@font-face {
  font-family: 'TT Commons Pro';
  src: url('/fonts/TTCommonsPro-Demibold.woff2') format('woff2'),
    url('/fonts/TTCommonsPro-Demibold.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Regular.woff2') format('woff2'),
    url('/fonts/Inter-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Medium.woff2') format('woff2'),
    url('/fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
}
